import {
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Stack,
} from "@mui/material";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import { ReactNode } from "react";

import Checkmark from "../../components/Icons/Check";
import { InternalButtonLink } from "../../components/Link";
import { OverviewLoginType } from "../../components/Overview";
import {
	BodyTextL,
	BodyTextM,
	HeadingM,
	HeadingXL,
} from "../../components/Typography";
import cs from "../../translations/cs.json";

const WelcomePage = ({
	name,
	logo,
	image,
}: {
	name: string;
	logo: ReactNode;
	image?: IGatsbyImageData;
}): JSX.Element => {
	return (
		<OverviewLoginType alt="Uvítací obrazovka" opacity={true}>
			<Stack sx={{ flexGrow: 1, px: 3 }} spacing={5}>
				<Stack
					sx={{
						flexDirection: "row",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					<BodyTextM>
						{cs.welcomePage.dearClient} {name}
					</BodyTextM>
					{logo}
				</Stack>
				<Stack spacing={4}>
					<HeadingXL mb={0}>{cs.welcomePage.welcome}</HeadingXL>
					<BodyTextL>{cs.welcomePage.description}</BodyTextL>
				</Stack>
				<Stack sx={{ flexDirection: "row" }} gap={4}>
					<Stack spacing={3}>
						<HeadingM mb={0}>{cs.welcomePage.whatYouGain}:</HeadingM>
						<List disablePadding>
							<ListItem disablePadding>
								<ListItemIcon>
									<Checkmark sx={{ fill: "#99CC33" }} />
								</ListItemIcon>
								<ListItemText
									primary={cs.welcomePage.benefitsList.detailedInfo}
								/>
							</ListItem>
							<ListItem disablePadding>
								<ListItemIcon>
									<Checkmark sx={{ fill: "#99CC33" }} />
								</ListItemIcon>
								<ListItemText primary={cs.welcomePage.benefitsList.overview} />
							</ListItem>
							<ListItem disablePadding>
								<ListItemIcon>
									<Checkmark sx={{ fill: "#99CC33" }} />
								</ListItemIcon>
								<ListItemText
									primary={cs.welcomePage.benefitsList.makeChanges}
								/>
							</ListItem>
							<ListItem disablePadding>
								<ListItemIcon>
									<Checkmark sx={{ fill: "#99CC33" }} />
								</ListItemIcon>
								<ListItemText primary={cs.welcomePage.benefitsList.summaries} />
							</ListItem>
						</List>
					</Stack>
					{image && (
						<Stack sx={{ alignSelf: "center" }}>
							<GatsbyImage image={image} alt={`${name} ilustrace`} />
						</Stack>
					)}
				</Stack>
				<InternalButtonLink to="/smlouvy/">
					{cs.global.continue}
				</InternalButtonLink>
			</Stack>
		</OverviewLoginType>
	);
};

export default WelcomePage;
