import { graphql, useStaticQuery } from "gatsby";
import { getImage, IGatsbyImageData } from "gatsby-plugin-image";

import AirBankLogo from "../../components/Icons/AirBank";
import { SEO } from "../../components/SEO";
import WelcomePage from "../../components/WelcomePage";
import withProtection from "../../highOrderComponents/withProtection";

function AirbankWelcomePage(): JSX.Element {
	interface imageProps {
		image: IGatsbyImageData;
		logo: {
			publicURL: string;
		};
	}

	const images = (): imageProps => {
		return useStaticQuery(
			graphql`
				query WelcomePageImage {
					image: file(relativePath: { eq: "airbankMan.png" }) {
						childImageSharp {
							gatsbyImageData
						}
					}
				}
			`
		);
	};
	const image = getImage(images().image);

	return (
		<WelcomePage
			name="AirBank"
			logo={<AirBankLogo sx={{ width: "104px", height: "40px" }} />}
			image={image}
		/>
	);
}

export default withProtection(AirbankWelcomePage, "uvitaci-obrazovka-airbank");

export const Head = (): JSX.Element => (
	<SEO title="Uvítací obrazovka AirBank" />
);
