import SvgIcon from "@mui/material/SvgIcon";

const AirBank = (props): JSX.Element => {
	return (
		<SvgIcon
			width="140"
			height="140"
			viewBox="33 33 70 70"
			fill="none"
			{...props}
		>
			<path
				d="M6.73186 63.7709C6.73186 65.1309 7.64578 66.6509 9.59282 66.6509C10.6657 66.6509 11.5399 66.1309 12.0962 65.4909C13.1293 64.2109 13.2088 62.6509 13.2088 60.4909C9.43388 60.1709 6.73186 61.3709 6.73186 63.7709ZM13.9439 70.0109C13.7452 68.7709 13.7551 67.8509 13.7551 67.2509C11.5299 70.4909 8.86765 70.4909 7.75505 70.4909C4.85435 70.4909 3.54308 69.6509 2.74837 68.8909C1.5563 67.6909 1 66.0509 1 64.4509C1 62.8509 1.5563 59.8909 5.25171 58.1709C7.71532 57.0509 11.2518 57.0509 13.1988 57.0509C13.1988 55.7309 13.1591 55.0909 12.9207 54.3709C12.4041 52.8909 10.8544 52.6909 10.2981 52.6909C9.46368 52.6909 8.5895 53.0509 8.07294 53.8109C7.63585 54.4909 7.63584 55.1309 7.63584 55.6109H1.87418C1.91392 54.5309 2.03313 52.3709 4.13911 50.8509C5.96695 49.5309 8.31135 49.2109 10.2187 49.2109C11.8876 49.2109 15.3843 49.4909 17.3711 51.8109C18.7221 53.4509 18.7618 55.8509 18.7618 56.7709L18.8016 64.8909C18.8016 66.6109 18.8314 68.3309 19.189 70.0109H13.9339H13.9439Z"
				fill="black"
			/>
			<path
				d="M28.5186 49.7344H22.6377V70.0144H28.5186V49.7344Z"
				fill="black"
			/>
			<path
				d="M37.9264 53.6083L37.8072 49.7283H32.2839C32.4031 50.8883 32.5621 53.8083 32.5621 54.7683V70.0083H38.443V60.0483C38.443 57.5283 38.8006 54.4483 44.7609 55.0083V49.6883C39.9926 49.4483 38.6814 51.8883 37.9264 53.6083Z"
				fill="black"
			/>
			<path
				d="M64.9843 69.8275C63.9512 69.8275 61.2889 69.9075 59.3021 72.5475V63.6875H53.4213V90.3075H59.1035C59.2227 89.0675 59.2624 88.1975 59.3021 87.5575C61.13 90.7175 64.3088 90.7075 65.3419 90.7075C69.3552 90.7075 73.7262 88.0675 73.7262 80.5075C73.7262 73.7075 69.8718 69.8275 64.9843 69.8275ZM63.6731 86.6975C61.0108 86.6975 59.0637 84.5375 59.0637 80.0275C59.0637 78.7775 59.0637 73.9075 63.3949 73.9075C67.7261 73.9075 67.7261 79.1275 67.7261 80.6975C67.7261 81.7175 67.7261 86.6975 63.6731 86.6975Z"
				fill="black"
			/>
			<path
				d="M93.4071 77.0678C93.4071 76.1478 93.3673 73.7478 92.0163 72.1078C90.0296 69.7878 86.5328 69.5078 84.8639 69.5078C82.9566 69.5078 80.6023 69.8278 78.7844 71.1478C76.6784 72.6678 76.5592 74.8278 76.5195 75.9078H82.2811C82.2811 75.4278 82.2811 74.7878 82.7182 74.1078C83.2348 73.3478 84.1089 72.9878 84.9434 72.9878C85.4997 72.9878 87.0494 73.1878 87.5659 74.6678C87.8044 75.3878 87.8441 76.0278 87.8441 77.3478C85.897 77.3478 82.3606 77.3478 79.897 78.4678C76.2016 80.1878 75.6453 83.1478 75.6453 84.7478C75.6453 86.3478 76.2016 87.9878 77.3936 89.1878C78.1883 89.9478 79.4996 90.7878 82.4003 90.7878C83.5129 90.7878 86.1752 90.7878 88.4004 87.5478C88.4004 88.1478 88.3607 89.0678 88.5593 90.3078H93.8342C93.4766 88.6278 93.4468 86.9078 93.4468 85.1878L93.4071 77.0678ZM86.7315 85.7878C86.1752 86.4278 85.301 86.9478 84.2282 86.9478C82.2811 86.9478 81.3672 85.4278 81.3672 84.0678C81.3672 81.6678 84.0692 80.4678 87.8441 80.7878C87.8441 82.9478 87.7646 84.5078 86.7315 85.7878Z"
				fill="black"
			/>
			<path
				d="M115.582 73.3547C115.065 71.9547 113.396 69.5547 109.462 69.5547C105.091 69.5547 103.492 72.1547 102.936 73.0747L102.777 70.0347H97.1344C97.2536 71.4347 97.2536 73.6347 97.2536 73.7147V90.3247H103.174V78.6547C103.174 77.0947 103.532 76.0947 103.889 75.5747C104.525 74.6147 105.678 74.0147 106.949 74.0147C108.459 74.0147 109.373 74.8547 109.81 75.7347C110.128 76.3747 110.128 77.0947 110.128 77.9347V90.3247H116.128V79.3947C116.128 75.6347 116.009 74.5947 115.572 73.3547H115.582Z"
				fill="black"
			/>
			<path
				d="M51.7874 49.7109L30.658 90.2909H36.7772L57.9067 49.7109H51.7874Z"
				fill="#96C320"
			/>
			<path
				d="M138.536 70.0294H131.9L125.841 78.4294V63.6094H119.999V90.3094H125.841V79.3094L132.049 90.3094H139.003L131.682 78.7894L138.536 70.0294Z"
				fill="black"
			/>
		</SvgIcon>
	);
};

export default AirBank;
